import ReactGA from 'react-ga4';

const reportWebVitals = onPerfEntry => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(metric => {
        onPerfEntry(metric);
        if (process.env.NODE_ENV === 'production') {
          ReactGA.event({
            category: 'Web Vitals',
            action: 'CLS',
            value: Math.round(metric.value * 1000),
            nonInteraction: true,
            label: metric.id
          });
        }
      });
      getFID(metric => {
        onPerfEntry(metric);
        if (process.env.NODE_ENV === 'production') {
          ReactGA.event({
            category: 'Web Vitals',
            action: 'FID',
            value: Math.round(metric.value),
            nonInteraction: true,
            label: metric.id
          });
        }
      });
      getFCP(metric => {
        onPerfEntry(metric);
        if (process.env.NODE_ENV === 'production') {
          ReactGA.event({
            category: 'Web Vitals',
            action: 'FCP',
            value: Math.round(metric.value),
            nonInteraction: true,
            label: metric.id
          });
        }
      });
      getLCP(metric => {
        onPerfEntry(metric);
        if (process.env.NODE_ENV === 'production') {
          ReactGA.event({
            category: 'Web Vitals',
            action: 'LCP',
            value: Math.round(metric.value),
            nonInteraction: true,
            label: metric.id
          });
        }
      });
      getTTFB(metric => {
        onPerfEntry(metric);
        if (process.env.NODE_ENV === 'production') {
          ReactGA.event({
            category: 'Web Vitals',
            action: 'TTFB',
            value: Math.round(metric.value),
            nonInteraction: true,
            label: metric.id
          });
        }
      });
    });
  }
};

export default reportWebVitals;
