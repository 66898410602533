import ReactGA from 'react-ga4';

// Use the environment variable for the tracking ID
const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;

// Initialize GA4
export const initGA = () => {
  if (process.env.NODE_ENV === 'production' && TRACKING_ID) {
    ReactGA.initialize(TRACKING_ID);
    console.log('Google Analytics initialized successfully');
  } else {
    console.log('Google Analytics initialized in development mode or missing tracking ID');
  }
};

// Track page views
export const pageView = (path) => {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.send({ hitType: 'pageview', page: path });
  } else {
    console.log(`Page view tracked: ${path}`);
  }
};

// Track events
export const trackEvent = (category, action, label, value) => {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.event({
      category,
      action,
      label,
      value
    });
  } else {
    console.log(`Event tracked: ${category}, ${action}, ${label}, ${value}`);
  }
};

// Track user timing
export const trackTiming = (category, variable, value, label) => {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.timing({
      category,
      variable,
      value,
      label
    });
  } else {
    console.log(`Timing tracked: ${category}, ${variable}, ${value}ms, ${label}`);
  }
}; 